import { Injectable } from '@angular/core';
import { Observable, of, race, timer } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';
import { EnvironmentService } from './environment.service';
import { UserService } from './user/user.service';

@Injectable({
  providedIn: 'root'
})
export class InitialDataService {

  constructor(
    private _env: EnvironmentService,
    private _user: UserService,
  ) {
  }
  resolve(): Observable<any> | Promise<any> | any {
    const req$ = this._user.getUserInfo();
    const timer$ = timer(2000)

    return race(req$, timer$)
      .pipe(
        mergeMap(result => {
          if (result === 0) {
            this._user.applyUserInfo({ language: 'en' })
            return of(true);
          }
          return of(result);
        }),
        switchMap(() => this._env.env$),
      );
  }
}
