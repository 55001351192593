import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, mergeMap, switchMap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CacheControlService } from '../services/cache-control.service';
import { PlatformService } from '../services/platform.service';
import { UserService } from '../services/user/user.service';

export interface CacheControl {
  cacheKey: string;
  version: number;
}

@Injectable({
  providedIn: 'root',
})
export class CacheControlInterceptor implements HttpInterceptor {

  constructor(
    private _cache: CacheControlService,
    private _platform: PlatformService,
    private _user: UserService
  ) {
  }

  /**
   * Can use from Environment cache
   * @private
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.includes('/cache/cf')) {
      req = this._updateUserHeaders(req);

      return next.handle(req);
    }

    if (!this._platform.isBrowser || !this._isRequestToCms(req)) {
      return next.handle(req);
    }

    return this._user.auth$.pipe(
      first(),
      switchMap(auth => {
        if (auth) {
          return this._cache.cacheAuth$ ? this._cache.cacheAuth$ : this._cache.updateCacheAuth();
        } else {
          return this._cache.cache$;
        }
      }
      ),
      mergeMap(cache => {
        const version = this._getCacheVersion(cache.data.cacheControl, req.url);

        if (version) {
          req = req.clone({
            setParams: { bcv: String(version) }
          });
          req = this._updateUserHeaders(req);
        }

        return next.handle(req);
      })
    );
  }


  /**
   * Check request to CMS
   * @param req
   * @private
   */
  private _isRequestToCms(req: HttpRequest<any>): boolean {
    return req.url.startsWith(environment.cms_host) && req.method === 'GET';
  }

  /**
   * Get cache version
   * @private
   */
  private _getCacheVersion(cache: CacheControl[] = [], requestUrl): string {
    const cacheItem = cache.find(c => requestUrl.includes(c.cacheKey));
    return cacheItem ? String(cacheItem.version) : null;
  }

  /**
   * Updates the request headers with user information.
   * @param req - The HTTP request to update.
   * @returns The updated HTTP request with the user headers set.
   */
  private _updateUserHeaders(req: HttpRequest<any>) {
    return req.clone({
      setHeaders: {
        'UID': this._user.info?.id?.toString() || '',
        'UC': this._user.info?.country?.toString() || '',
        'U-GROUP': this._user.info?.statuses?.map(status => status.id)?.join(',') || ''
      }
    });
  }
}

